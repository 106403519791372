<template>
    <v-card
        :loading="!(item && options)"
    >
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row v-if="item && options">
                    <v-col cols="12">
                        <v-text-field
                            v-model="item.label"
                            :label="trans('fields.common.label')"
                            :error-messages="errors.label"
                            @input="clearError('label')"
                        ></v-text-field>
                    </v-col>
                    <template v-if="options.locales">
                        <v-col cols="6"
                            v-for="locale in options.locales"
                            :key="'translation-'+locale"
                        >
                            <v-text-field
                                v-model="item.translations[locale]"
                                :label="trans('fields.common.locale') + ' ' + locale"
                                :error-messages="errors['translations.'+locale]"
                                @input="clearError('translations.'+locale)"
                            ></v-text-field>
                        </v-col>
                    </template>
                    <v-col cols="12">
                        <v-select
                            :items="options.planScopes"
                            :label="trans('fields.common.scope')"
                            :multiple="false"
                            v-model="item.scope"
                            :error-messages="errors['scope']"
                            @input="clearError('scope')"
                            dense
                        ></v-select>
                        <v-switch
                            v-model="item.is_default"
                            :label="trans('fields.plan.is_default')"
                            :error-messages="errors.is_default"
                            @input="clearError('is_default')"
                            :true-value="1"
                            :false-value="0"
                            color="green"
                        ></v-switch>
                    </v-col>
                </v-row>
                <template v-if="item.prices && item.prices.length && options">
                    <v-list>
                        <v-list-item
                            v-for="(price, index) in item.prices"
                            :key="index"
                        >
                            <v-list-item-content>
                                <v-row>
                                    <v-col cols="12" md="3">
                                        <v-select
                                            :items="options.planCurrencies"
                                            :label="trans('fields.common.currency')"
                                            :multiple="false"
                                            v-model="item.prices[index].currency"
                                            :error-messages="errors['prices.'+index+'.currency']"
                                            @input="clearError('prices.'+index+'.currency')"
                                            dense
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            v-model="item.prices[index].monthly"
                                            :label="trans('fields.plan.monthly')"
                                            :error-messages="errors['prices.'+index+'.monthly']"
                                            @input="clearError('prices.'+index+'.monthly')"
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            v-model="item.prices[index].halfYearly"
                                            :label="trans('fields.plan.halfYearly')"
                                            :error-messages="errors['prices.'+index+'.halfYearly']"
                                            @input="clearError('prices.'+index+'.halfYearly')"
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            v-model="item.prices[index].yearly"
                                            :label="trans('fields.plan.yearly')"
                                            :error-messages="errors['prices.'+index+'.yearly']"
                                            @input="clearError('prices.'+index+'.yearly')"
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="deletePrice(index)">
                                    <v-icon color="red lighten-1">mdi-delete</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </template>
                <v-row>
                    <v-btn color="primary" dark @click="addPrice">{{ trans('buttons.common.addPrice') }}</v-btn>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions v-if="item && options">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">{{ trans('buttons.common.close') }}</v-btn>
            <v-btn v-if="item.id" color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="blue darken-1" text @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";

    export default {
        name: "PlanForm",
        mixins: [formValidate],
        props: {
            itemId: {
                type: [Number, String],
            },
        },
        data(){
            return {
                showRoute: 'admin.plans.show',
                item: {
                    id: null,
                    label: '',
                    scope: null,
                    prices: [],
                    translations: {},
                },
                defaultPrice: {
                    currency: null,
                    monthly: null,
                    halfYearly: null,
                    yearly: null,
                },
                error: null,
                options: null,
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                let id = this.itemId || this.item.id;
                if (id) {
                    axios.get(this.route(this.showRoute, id)).then(response => {
                        this.item = response.data;
                        this.loadOptions();
                    }).catch(err => {
                        this.error = err.response.data;
                    });
                } else {
                    this.loadOptions();
                }
            },

            addPrice(){
                this.item.prices.push(JSON.parse(JSON.stringify(this.defaultPrice)));
            },
            deletePrice(index){
                this.item.prices.splice(index, 1);
            },

            loadOptions(){
                axios.post(this.route('admin.options'), {
                    locales: true,
                    planScopes: true,
                    planCurrencies: true,
                }).then( response => {
                    this.options = response.data;
                })
            },
            save() {
                if (!this.item.id) {
                    axios.post(this.route('admin.plans.store'), this.item).then((response) => {
                        console.log('save success');
                        this.item = response.data;
                        this.$emit('saved');
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                } else {
                    axios.post(this.route('admin.plans.update', this.item.id), this.item).then(() => {
                        console.log('save success');
                        this.$emit('updated');
                    }).catch( (err) => {
                        let errors = {};
                        _.each(err.response.data.errors, (list, name) => {
                            _.set(errors, name, list);
                        });
                        this.errors = errors;
                        console.log(err.response.data);
                    })
                }
            },
        },
        computed: {
            formTitle() {
                return !this.item.id
                    ? this.trans('pages.plan.newDialogTitle')
                    : this.trans('pages.plan.editDialogTitle');
            },
        }
    }
</script>
